@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.App {
  text-align: center;
  margin: 20px;
}

body {
  font-family: "Poppins", sans-serif !important;
  background: black;
  max-width: 1250px;
  min-width: 350px;
  margin: auto;

  cursor: default;
  height: 100%;
  width: 100%;

  background: linear-gradient(to right, #11141D, #1A2330);
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.image-fade-in {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s; /* Adjust duration to control speed */
  opacity: 0; /* Start with a transparent image */
}

.logo {
  width: 256px;
  margin-top: 36px;
  transition: filter 0.3s ease;
}
.logo:hover {
  filter: brightness(1.2);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
